export const CornerBufos = () => {
  return (
    <>
      <img
        src="/bufos/bufo-all-warm-and-fuzzy-inside.png"
        alt="Bufo"
        className="fixed bottom-0 right-0 h-[64px] w-64px z-0"
      />
    </>
  );
};
