export const proverbs = [
  "A bad excuse is better than none",
  "A bad penny always turns up",
  "A bad workman blames his tools",
  "A bird in the hand is worth two in the bush",
  "A cat may look at a king",
  "A chain is only as strong as its weakest link",
  "A dog is a man's best friend",
  "A drowning man will clutch at a straw",
  "A fool and his money are soon parted",
  "A friend in need is a friend indeed",
  "A friend to everyone is a friend to no one",
  "A journey of a thousand miles begins with a single step",
  "A little learning is a dangerous thing",
  "A leopard cannot change its spots",
  "A man can do what he wills but he cannot will what he wills",
  "A mill cannot grind with the water that is past",
  "A miss is as good as a mile",
  "A new language is a new life",
  "A penny saved is a penny earned",
  "A picture is worth a thousand words",
  "A rising tide lifts all boats",
  "A rolling stone gathers no moss",
  "A ship in a harbour is safe, but that's not what a ship is for",
  "A stitch in time saves nine",
  "A watched man never plays",
  "A watched pot never boils",
  "Absence makes the heart grow fonder",
  "Absolute power corrupts absolutely",
  "Accidents will happen in the best-regulated families",
  "Actions speak louder than words",
  "Adversity makes strange bedfellows",
  "All good things come to him who waits",
  "All good things must come to an end",
  "All hands on deck",
  "All is grist that comes to the mill",
  "All roads lead to Rome",
  "All that glitters is not gold",
  "All the world loves a lover",
  "All things come to those who wait",
  "All things must pass",
  "All work and no play makes Jack a dull boy",
  "All you need is love",
  "All is fair in love and war",
  "All is for the best in the best of all possible worlds",
  "All is well that ends well",
  "An apple a day keeps the doctor away",
  "An army marches on its stomach",
  "An eye for an eye, a tooth for a tooth",
  "An eye for an eye makes the whole world blind",
  "An Englishman's home is his castle",
  "Another day, another dollar",
  "Another happy landing",
  "An ounce of prevention is worth a pound of cure",
  "Any port in a storm",
  "Any publicity is good publicity",
  "April showers bring forth May flowers",
  "As a tree bends, so shall it grow",
  "As the twig is bent, so is the tree inclined",
  "As you make your bed, so you must lie upon it",
  "As you sow so shall you reap",
  "Ask a silly question and you will get a silly answer",
  "Ask my companion if I be a thief",
  "Ask no questions and hear no lies",
  "Attack is the best form of defense",
  "At the end of my rope",
  "Bad news travels fast",
  "Barking dogs seldom bite",
  "Beauty is in the eye of the beholder",
  "Beauty is only skin deep",
  "Beggars cannot be choosers",
  "Behind every great man, there is a great woman",
  "Better late than never",
  "Better safe than sorry",
  "Better to reign in hell than serve in heaven",
  "Be yourself",
  "Better the Devil you know than the Devil you do not",
  "Better to have loved and lost than never to have loved at all",
  "Better to light one candle than to curse the darkness",
  "Better to remain silent and be thought a fool than to speak and remove all doubt",
  "Better wear out than rust out",
  "Beware of Greeks bearing gifts",
  "Big fish eat little fish",
  "Birds of a feather flock together",
  "Blood is thicker than water",
  "Born with a silver spoon in one's mouth",
  "Boys will be boys",
  "Brevity is the soul of wit",
  "Business before pleasure",
  "Caesar's wife must be above suspicion",
  "Careless talk costs lives",
  "Charity begins at home",
  "Cheats never prosper",
  "Cheese, wine, and friends must be old to be good.",
  "Children should be seen and not heard",
  "Christmas comes but once a year",
  "Cleanliness is next to godliness",
  "Clothes don't make the man",
  "Clothes make the man",
  "Coffee and love taste best when hot. Ethiopian proverb",
  "Cold hands, warm heart",
  "Comparisons are odious",
  "Count your blessings",
  "Courage is the measure of a Man, Beauty is the measure of a Woman",
  "Cowards may die many times before their death",
  "Crime does not pay",
  "Cream rises",
  "Criss-cross, applesauce",
  "Cross the stream where it is shallowest",
  "Cut your coat according to your cloth",
  "Curiosity killed the cat",
  "Dead men tell no tales",
  "Denial is not a river in Egypt",
  "Devil take the hindmost",
  "Discretion is the better part of valour",
  "Do as I say, not as I do",
  "Do as you would be done by",
  "Do unto others as you would have them do unto you",
  "Do not bite the hand that feeds you",
  "Do not burn your bridges behind you",
  "Do not carry coals to Newcastle",
  "Do not cast your pearls before swine",
  "Do not cry over spilled milk",
  "Do not change horses in midstream",
  "Do not count your chickens before they are hatched",
  "Do not cross the bridge till you come to it",
  "Do not cut off your nose to spite your face",
  "Do not judge a book by its cover",
  "Do not keep a dog and bark yourself",
  "Do not let the bastards grind you down",
  "Do not let the grass grow beneath one's feet",
  "Do not look a gift horse in the mouth",
  "Do not make a mountain out of a mole hill",
  "Do not meet troubles half-way",
  "Do not put all your eggs in one basket",
  "Do not put the cart before the horse",
  "Do not put too many irons in the fire",
  "Do not put new wine into old bottles",
  "Do not put off until tomorrow what you can do today",
  "Do not rock the boat",
  "Do not lock the stable door after the horse has bolted",
  "Do not spend it all in one place",
  "Do not spoil the ship for a ha'porth of tar",
  "Do not throw pearls to swine",
  "Do not teach your Grandmother to suck eggs",
  "Do not throw the baby out with the bathwater",
  "Do not try to walk before you can crawl",
  "Do not upset the apple-cart",
  "Do not wash your dirty linen in public",
  "Do not sympathize with those who can not empathize",
  "Do unto others as you would have them do unto you. Often referred to as the Golden Rule",
  "Doubt is the beginning, not the end, of wisdom",
  "Early to bed and early to rise makes a man healthy, wealthy and wise, polymath and Founding Father of the United States)",
  "Easier said than done",
  "East is east, and west is west",
  "East, west, home is best",
  "Easy come, easy go",
  "Easy, times easy, is still easy",
  "Early marriage, earlier pregnant",
  "Eat breakfast like a king, lunch like a prince and dinner like a pauper",
  "Eat, drink and be merry, for tomorrow we die",
  "Empty vessels make the most noise",
  "Enough is as good as a feast",
  "Even a worm will turn",
  "Even from a foe a man may learn wisdom",
  "Every cloud has a silver lining",
  "Every dog has his day",
  "Every Jack has his Jill",
  "Every little bit helps",
  "Every man for himself and the Devil take the hindmost",
  "Every man has his price",
  "Every picture tells a story",
  "Every stick has two ends",
  "Everybody wants to go to heaven but nobody wants to die",
  "Everyone has their price",
  "Everything comes to those who wait",
  "Every tide has its ebb",
  "Failing to plan is planning to fail",
  "Faint heart never won fair lady",
  "Fair exchange is no robbery",
  "Faith will move mountains",
  "Fall seven times, stand up eight",
  "False friends are worse than open enemies",
  "Fake it till you make it",
  "Familiarity breeds contempt",
  "Feed a cold, starve a fever",
  "Fight fire with fire",
  "Fine feathers make fine birds",
  "Finders keepers losers weepers",
  "Fine words butter no parsnips",
  "First come, first served",
  "First impressions are the most lasting",
  "First things first",
  "Fish always rots from the head downwards",
  "Fish and guests smell after three days",
  "Flattery will get you nowhere",
  "Fools rush in where angels fear to tread",
  "For want of a nail the shoe was lost; for want of a shoe the horse was lost; and for want of a horse the man was lost",
  "Forewarned is forearmed",
  "Fortune favours the bold",
  "Free is for me",
  "From the sublime to the ridiculous is only a step",
  "Genius is an infinite capacity for taking pains",
  "Give a dog a bad name and hang him",
  "Give a man a fish and you feed him for a day. Teach a man to fish and you feed him for a lifetime",
  "Give a man rope enough and he will hang himself",
  "Give credit where credit is due",
  "Give him an inch and he will take a mile",
  "Give the devil his due",
  "God helps those who help themselves",
  "Good fences make good neighbours",
  "Good talk saves the food",
  "Good things come to those who wait",
  "Great minds think alike",
  "Half a loaf is better than no bread",
  "Handsome is as handsome does",
  "Hard cases make bad law",
  "Hard work never did anyone any harm",
  "Haste makes waste",
  "He that goes a-borrowing, goes a-sorrowing",
  "He who can, does; he who cannot, teaches",
  "He who hesitates is lost",
  "He who laughs last laughs longest",
  "He who lives by the sword, dies by the sword",
  "He who loves the world as his body may be entrusted with the empire",
  "He who makes a beast out of himself gets rid of the pain of being a man",
  "He who pays the piper calls the tune",
  "He who knows does not speak. He who speaks does not know",
  "He who sups with the Devil should have a long spoon",
  "Hell hath no fury like a woman scorned; Heav'n has no Rage, like Love to Hatred turn'd, Nor Hell a Fury, like a Woman scorn'd",
  "Hindsight is always twenty-twenty",
  "History repeats itself",
  "Home is where the heart is",
  "Honesty is the best policy",
  "Hope for the best, and prepare for the worst",
  "Hope springs eternal",
  "Horses for courses",
  "Hunger never knows the taste, sleep never knows the comfort",
  "Idle hands are the devil's playthings",
  "If anything can go wrong, it will",
  "If a job is worth doing, it is worth doing well",
  "If at first you do not succeed, try, try again",
  "If God had meant us to fly, he would have given us wings",
  "If ifs and ands were pots and pans, there would be no work for tinkers",
  "If it ain't broke, don't fix it",
  "If it were not for hope the heart would break",
  "If it were a snake, it would have bit you",
  "If the shoe fits, wear it",
  "If the mountain will not come to Mohammed, then Mohammed must go to the mountain",
  "If wealth is lost, nothing is lost. If health is lost, something is lost. If character is lost, everything is lost",
  "If wishes were horses, beggars would ride",
  "If you're growing in age, then you're nearing to the graveyard",
  "If you cannot be good, be careful",
  "If you cannot beat them, join them",
  "If you cannot live longer, live deeper",
  "If you cannot stand the heat, get out of the kitchen",
  "If you give a mouse a cookie, he'll always ask for a glass of milk",
  "If you think that you know everything, then you're a Jack ass",
  "If you lie down with dogs, you will get up with fleas",
  "If you pay peanuts, you get monkeys",
  "If you play with fire, you will get burned",
  "If you steal from one author, it is plagiarism; if you steal from many, it is research, American writer and entrepreneur)",
  "If you want a thing done well, do it yourself",
  "If you have never seen the bottom of the tree, you cannot know how tall it stands",
  "If you must dance with the Devil, you might as well know his favorite song",
  "If you've got it, flaunt it",
  "Ignorance is bliss",
  "Imitation is the sincerest form of flattery",
  "In for a penny, in for a pound",
  "March comes in like a lion, and goes out like a lamb",
  "In the kingdom of the blind, the one eyed man is king",
  "In the midst of life, we are in death",
  "Into every life a little rain must fall",
  "It ain't over till it's over",
  "It ain't over till the fat lady sings",
  "It ain't what you don't know that gets you into trouble. It's what you know for sure that just ain't so",
  "It goes without saying",
  "It is a small world",
  "It is all grist to the mill",
  "It is an ill wind that blows no one any good",
  "It is best to be on the safe side",
  "It is better to be smarter than you appear than to appear smarter than you are",
  "It is better to give than to receive",
  "It is better to have loved and lost than never to have loved at all",
  "It is better to cultivate a Land with two Bulls, rather working under Boss who never gives Wage when asked",
  "It is better to light a candle than curse the darkness",
  "It is better to travel hopefully than to arrive",
  "It is easy to be wise after the event",
  "It's Greek to me",
  "It is never too late",
  "It is no use crying over spilt milk",
  "It is no use locking the stable door after the horse has bolted",
  "It is not enough to learn how to ride, you must also learn how to fall",
  "It is on",
  "It is the early bird that gets the worm",
  "It is the empty can that makes the most noise",
  "It is the squeaky wheel that gets the grease",
  "It is what it is",
  "It needs a hundred lies to cover a single lie",
  "It never rains but it pours",
  "It takes a thief to catch a thief",
  "It takes a whole village to raise a child",
  "It takes all sorts to make a world",
  "It takes one to know one",
  "It takes two to tango",
  "I'm going to have to give you the pink slip",
  "It will come back and haunt you",
  "It will be the same a hundred years hence",
  "Islands depend on reeds, just as reeds depend on islands",
  "Jack of all trades, master of none",
  "Judge not, that ye be not judged",
  "Keep your chin up",
  "Keep your friends close and your enemies closer",
  "Keep your powder dry",
  "Kill the chicken to scare the monkey",
  "Kill the goose that lays the golden eggs",
  "Kill two birds with one stone.",
  "Kindness in words creates confidence. Kindness in thinking creates profoundness. Kindness in giving creates love",
  "Know which side one's bread is buttered on",
  "Knowledge is power, guard it well",
  "A language is a dialect with an army and navy",
  "The last drop makes the cup run over",
  "Laugh before breakfast, cry before supper",
  "Laugh and the world laughs with you, weep and you weep alone",
  "Laughter is the best medicine",
  "Late lunch makes day go faster",
  "Learn a language, and you will avoid a war",
  "Least said, soonest mended",
  "Less is more",
  "Let bygones be bygones",
  "Let not the sun go down on your wrath",
  "Let sleeping Aussies lie",
  "Let sleeping dogs lie",
  "Let the buyer beware",
  "Let the cat out of the bag",
  "Let the dead bury the dead",
  "Let the punishment fit the crime",
  "Let well alone",
  "Let your hair down",
  "Life begins at forty",
  "Life is too short not to do something that matters.",
  "Life is not all beer and skittles",
  "Life is what you make it",
  "Lightning never strikes twice in the same place",
  "Like father, like son",
  "Little pitchers have big ears",
  "Little strokes fell great oaks",
  "Little things please little minds",
  "Live and let live",
  "Live for today, for tomorrow never comes",
  "Live to fight another day",
  "Loose lips sink ships",
  "Look before you leap",
  "Love is blind",
  "Love of money is the root of all evil",
  "Love makes the world go around",
  "Love will find a way",
  "Make hay while the sun shines",
  "Make love not war",
  "Man does not live by bread alone",
  "Man proposes, heaven disposes",
  "Manners maketh man",
  "Many a little makes a mickle",
  "Many a mickle makes a muckle",
  "Many a true word is spoken in jest",
  "Many hands make light work",
  "March comes in like a lion and goes out like a lamb",
  "Marriages are made in heaven",
  "Marry in haste, repent at leisure",
  "Memory is the treasure of the mind",
  "Men are blind in their own cause",
  "Men get spoiled by staying, women get spoiled by wandering",
  "Might is right",
  "Might makes right",
  "Mighty oaks from little acorns grow",
  "Milking the bull",
  "Misery loves company",
  "Moderation in all things",
  "Money does not grow on trees",
  "Money earned by deceit, goes by deceit",
  "Money is not everything",
  "Money demands care, you abuse it and it disappears",
  "Money makes the world go around",
  "Money talks",
  "Money makes many things, but also makes devil dance",
  "More haste, less speed",
  "Mud sticks",
  "Music has charms to soothe the savage beast",
  "Nature abhors a vacuum",
  "Necessity is the mother of invention",
  "Needs must when the devil drives",
  "Never cast a clout until May be out",
  "Never give advice unless asked",
  "Never give a sucker an even break",
  "Never judge a book by its cover",
  "Never let the sun go down on your anger",
  "Never let the truth get in the way of a good story",
  "Never look a gift horse in the mouth",
  "Never put off until tomorrow what you can do today",
  "Never reveal a man's wage, and woman's age",
  "Never speak ill of the dead",
  "Never say die",
  "Never say never",
  "Never tell tales out of school",
  "Never too old to learn",
  "Nine tailors make a man,",
  "No friends but the mountains",
  "No guts, no glory",
  "No man can serve two masters",
  "No man is an island",
  "No names, no pack-drill",
  "No news is good news",
  "No one can make you feel inferior without your consent",
  "No pain, no gain",
  "No rest for the wicked",
  "Not all those who wander are lost",
  "Nothing is certain but death and taxes",
  "Nothing succeeds like success",
  "Nothing ventured, nothing gained",
  "Oil and water do not mix",
  "Old soldiers never die, they simply fade away.",
  "Once bitten, twice shy",
  "One good turn deserves another",
  "Once the poison, twice the charm",
  "One half of the world does not know how the other half lives",
  "One hand washes the other",
  "One kind word can warm three winter months",
  "One man's meat is another man's poison",
  "One man’s terrorist is another man’s freedom fighter",
  "One man's trash is another man's treasure",
  "One might as well be hanged for a sheep as a lamb",
  "One might as well throw water into the sea as to do a kindness to rogues",
  "One law for the rich and another for the poor",
  "Opportunity does not knock until you build a door",
  "One swallow does not make a summer",
  "One who believes in Sword, dies by the Sword",
  "One who speaks only one language is one person, but one who speaks two languages is two people. Turkish Proverb",
  "One year's seeding makes seven years weeding",
  "Only fools and horses work",
  "Open confession is good for the soul.",
  "Opportunity never knocks twice at any man's door",
  "Other times other manners.",
  "Out of sight, out of mind",
  "Out of the frying pan and into the fire",
  "Out of the mouths of babes",
  "Over greedy man, over wrathful woman will never flourish",
  "Parsley seed goes nine times to the Devil",
  "Patience is a virtue",
  "Pearls of wisdom",
  "Penny wise and pound foolish",
  "Penny, Penny. Makes many.",
  "People who live in glass houses should not throw stones",
  "Physician, heal thyself",
  "Possession is nine-tenths of the law",
  "Power corrupts; absolute power corrupts absolutely",
  "Practice makes perfect",
  "Practice what you preach",
  "Preaching to the choir",
  "Prevention is better than cure",
  "Pride goes before a fall",
  "Procrastination is the thief of time",
  "Putting the cart before the horse",
  "Put your best foot forward",
  "Put your money where your mouth is",
  "Red sky at night shepherds delight; red sky in the morning, shepherds warning",
  "Respect is not given, it is earned.",
  "Revenge is a dish best served cold",
  "Revenge is sweet",
  "Rome was not built in one day",
  "Right or wrong, my country",
  "Rules were made to be broken.",
  "See a pin and pick it up, all the day you will have good luck; See a pin and let it lay, bad luck you will have all day",
  "See no evil, hear no evil, speak no evil",
  "Seeing is believing",
  "Seek and ye shall find",
  "Set a thief to catch a thief",
  "Shiny are the distant hills",
  "Shrouds have no pockets",
  "Speech is silver but silence is golden",
  "Sit crooked and talk straight",
  "Slow and steady wins the race",
  "Slow but sure",
  "Smooth move",
  "Snake in the grass",
  "Softly, softly, catchee monkey",
  "Some are more equal than others",
  "Sometimes we are the student. Sometimes we are the master. And sometimes we are merely the lesson",
  "Spare the rod and spoil the child",
  "Speak as you find",
  "Speak of the devil and he will appear",
  "Speak softly and carry a big stick",
  "Sticks and stones may break my bones, but words will never hurt me",
  "Still waters run deep",
  "Strike while the iron is hot",
  "Stupid is as stupid does",
  "Success has many fathers, while failure is an orphan",
  "A swarm in May is worth a load of hay; a swarm in June is worth a silver spoon; but a swarm in July is not worth a fly",
  "Take care of the pennies, and the pounds will take care of themselves",
  "Talk is cheap",
  "Talk of the Devil, and he is bound to appear",
  "Talk of Angels, and hear the flutter of their wings",
  "Tell me who your friends are, and I'll tell you who you are",
  "Tell the truth and shame the Devil",
  "The age of miracles is past",
  "The apple does not fall far from the tree",
  "The best condiments are authentic flavors",
  "The best defense is a good offense",
  "The best-laid schemes of mice and men often go awry",
  "The best things in life are free",
  "The bigger they are, the harder they fall",
  "The boy is father to the man",
  "The bread never falls but on its buttered side",
  "The child is the father of the man",
  "The cobbler always wears the worst shoes",
  "The comeback is greater than the setback",
  "The course of true love never did run smooth",
  "The customer is always right",
  "The darkest hour is just before the dawn",
  "The Devil finds work for idle hands to do",
  "The Devil looks after his own",
  "The die is cast",
  "The early bird catches the worm",
  "The end justifies the means",
  "The enemy of my enemy is my friend",
  "The exception which proves the rule",
  "The female of the species is more deadly than the male",
  "The good die young",
  "The grass is always greener on the other side",
  "The hand that rocks the cradle rules the world",
  "The husband is always the last to know",
  "The innocent seldom find an uncomfortable pillow",
  "The labourer is worthy of his hire",
  "It is the last straw that breaks the camel's back",
  "The law is an ass",
  "The leopard does not change his spots",
  "The left hand doesn't know what the right hand is doing",
  "The light is on but nobody is home",
  "The longest day must have an end",
  "The longest journey starts with a single step",
  "The Moon is made of green cheese",
  "The more the merrier",
  "The more things change, the more they stay the same",
  "The only disability in life is a bad attitude",
  "The only way to understand a woman is to love her",
  "The old wooden spoon beats me down",
  "The only way to find a friend is to be one",
  "The pen is mightier than the sword",
  "The pot calling the kettle black",
  "The proof of the pudding is in the eating",
  "The road to Hell is paved with good intentions",
  "The shoemaker's son always goes barefoot",
  "The squeaky wheel gets the grease",
  "The streets are paved with gold",
  "The way to a man's heart is through his stomach",
  "The work praises the man.",
  "There ain't no such thing as a free lunch",
  "There are more ways of killing a cat than choking it with cream",
  "There are none so blind as those who will not see",
  "There are two sides to every question",
  "There but for the grace of God go I",
  "There is an exception to every rule",
  "There are always more fish in the sea",
  "There is honour among thieves",
  "There is many a good tune played on an old fiddle",
  "There is many a slip 'twixt cup and lip",
  "There is more than one way to skin a cat",
  "There is no accounting for tastes",
  "There is no fool like an old fool",
  "There's no need to wear a hair shirt",
  "There is no place like home",
  "There is no shame in not knowing; the shame lies in not finding out.",
  "There is no smoke without fire",
  "There is no such thing as a free lunch",
  "There is no such thing as bad publicity",
  "There is no time like the present",
  "There are none so deaf as those who will not hear",
  "There's nowt so queer as folk",
  "There is one born every minute",
  "There is safety in numbers",
  "They that sow the wind shall reap the whirlwind",
  "Third time is a charm",
  "Those who do not learn from history are doomed to repeat it",
  "Those who live in glass houses should not throw stones",
  "Those who know many languages live as many lives as the languages they know",
  "Those who sleep with dogs will rise with fleas",
  "Time and tide wait for no man",
  "Time flies",
  "Time goes by slowly when your are living intensely",
  "Time is a great healer",
  "Time is money",
  "Only time will tell",
  "'Tis better to have loved and lost than never to have loved at all",
  "To be worn out is to be renewed",
  "To each his own",
  "To err is human, to forgive divine",
  "To learn a language is to have one more window from which to look at the world",
  "To the victor go the spoils",
  "To travel hopefully is a better thing than to arrive",
  "Tomorrow is another day",
  "Tomorrow never comes",
  "Too many cooks spoil the broth",
  "Too little, too late",
  "Too much of a good thing",
  "Truth is stranger than fiction",
  "Truth is more valuable if it takes you a few years to find it",
  "Like trying to grow a goose",
  "The truth will out",
  "Turn your face toward the sun and the shadows fall behind you",
  "Two birds with one stone",
  "Two can play at that game",
  "Two heads are better than one",
  "Two is company, but three is a crowd",
  "Two wrongs do not make a right",
  "Uneasy lies the head that wears a crown",
  "United we stand, divided we fall",
  "Use it or lose it",
  "Ugly is as ugly does",
  "Up a creek without a paddle",
  "Unity is strength",
  "Variety is the spice of life.",
  "Virtue is its own reward",
  "Walk softly but carry a big stick",
  "Walls have ears",
  "Walnuts and pears you plant for your heirs",
  "Waste not, want not",
  "Well begun is half done",
  "What does not kill me makes me stronger",
  "Well done is better than well said",
  "What cannot be cured must be endured",
  "What goes around, comes around",
  "What goes up must come down",
  "What you lose on the swings you gain on the roundabouts",
  "What is sauce for the goose is sauce for the gander",
  "What the eye does not see the heart does not grieve over",
  "When in Rome, do as the Romans do.",
  "Whatever floats your boat",
  "When it rains it pours",
  "When life gives you lemons, make lemonade",
  "When the cat is away, the mice will play",
  "When the going gets tough, the tough get going",
  "When the oak is before the ash, then you will only get a splash; when the ash is before the oak, then you may expect a soak",
  "When you have seen one, you have seen them all",
  "What is learnt in the cradle lasts to the tombs",
  "What the eye does not see, the heart does not grieve over",
  "Where there is a will there is a way",
  "Where there is muck there is brass",
  "Where there is life there is hope",
  "Whether you think you can, or you think you can't, you're right",
  "While there is life there is hope",
  "Who will bell the cat?",
  "Whom the Gods love die young",
  "Why keep a dog and bark yourself?",
  "With great power comes great responsibility",
  "Wonders will never cease",
  "Work expands so as to fill the time available",
  "Worrying never did anyone any good",
  "You are never too old to learn",
  "You are what you eat",
  "You can have too much of a good thing",
  "You can lead a horse to water, but you cannot make it drink",
  "You can never can tell",
  "You cannot always get what you want",
  "You cannot burn a candle at both ends.",
  "You cannot have your cake and eat it too",
  "You cannot get blood out of a stone",
  "You cannot make a silk purse from a sow's ear",
  "You cannot make an omelette without breaking eggs",
  "You cannot make bricks without straw",
  "You cannot push a rope",
  "You cannot run with the hare and hunt with the hounds",
  "You cannot teach an old dog new tricks",
  "You cannot unscramble eggs",
  "You cannot win them all",
  "You catch more flies with honey than with vinegar",
  "You either die a hero or live long enough to see yourself become the villain",
  "You pay your money and you take your choice",
  "Youth is wasted on the young",
  "You must have rocks in your head",
  "You scratch my back and I will scratch yours",
  "You'll never get if you never go",
  "You're never fully dressed without a smile",
  "You've got to separate the wheat from the chaff",
  "You've made your bed and you must lie in it",
  "Zeal without knowledge is fire without light",
];
